export const archiveSelector = (
   
  archiveData: ArchiveData,
  yearSelect: HTMLSelectElement,
  monthSelect: HTMLSelectElement
): void => {
  yearSelect.addEventListener('change', function (event) {
    const userSelected = (event.target as HTMLSelectElement).value
    const monthSelectValues: { [k: string]: any } | undefined = dig(
      archiveData,
      userSelected
    )
    const options: string[] = []
    for (const property in monthSelectValues) {
      if (Object.prototype.hasOwnProperty.call(monthSelectValues, property)) {
        const monthNumberValue: string = property
        const monthName = monthSelectValues[property]
        options.push(
          `<option value="${monthNumberValue}">
              ${String(monthName)}
            </option>`
        )
      }
    }

    const sortedOptions = options.sort()
    monthSelect.innerHTML = sortedOptions.toString()
  })
}

export const dig = (
  obj: { [k: string]: any },
  target: string
): object | undefined =>
  target in obj
    ? obj[target]
    : Object.keys(obj)
        .map(v => obj[v])
         
        .reduce((acc, val) => {
          if (acc !== undefined) return acc
          if (typeof val === 'object') return dig(val, target)
        }, undefined)
